import {Card, CircularProgress, FormControlLabel, Switch} from '@mui/material'
import Button from '@mui/material/Button'
import {useState} from 'react'
import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {UploadedResult, UploadFile} from './UploadFile'
import {StylesList} from './StylesList'



export default function App() {
  const [{fileUrl, noBGFileUrl, modelsUrls, noBGModelsUrls}, setOnUploaded] = useState<UploadedResult>({
    fileUrl: '',
    noBGFileUrl: '',
    filename: '',
    modelsUrls: {},
    noBGModelsUrls: {}
  });
  const [style, setStyle] = useState<string>('');
  const [isStylizing, setIsStylizing] = useState<boolean>(false);
  const [isBG, setBG] = useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBG(event.target.checked);
    setIsStylizing(true);
  };
  const currentFileUrl = isBG ? fileUrl : noBGFileUrl;
  const selectedStyleURLs = isBG ? modelsUrls : noBGModelsUrls;
  return (
    <Container sx={{display: 'flex', flexDirection: 'raw', minWidth: '730px'}}>
      <Box sx={{ width: "30%", display: 'block', height: '98vh' }}>
        <StylesList selectedKey={style} onSelected={(style) => {
          if (fileUrl && modelsUrls[style]){
            setBG(true);
            setIsStylizing(true);
            setStyle(style);
          }
        }} />
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', gap: 2, width: '600px', height: '600px', my: '10%' }}>
        <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '512px', gap: 2, position: 'relative', height: '512px' }}>
          {!currentFileUrl? (<UploadFile onUploaded={data => {
            setOnUploaded(data);
          }} />): (
            <img
              key={style}
              src={style ? selectedStyleURLs[style] : currentFileUrl}
              alt="uploaded file"
              height="512"
              onLoad={() => setIsStylizing(false)}
            />
          )}
          {isStylizing && <CircularProgress size="3rem" sx={{position: 'absolute', top: '47%', left: '47%', transform: 'translate(-50%, -50%)'}} />}
        </Card>
        <Box sx={{ width: '600px', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', gap: 2 }}>
          <Button
            disabled={style === ''}
            variant="contained"
            color="warning"
            onClick={() => {
              setStyle('');
              setIsStylizing(true);
            }}
            sx={{ width: '200px', height: '50px' }}
          >
            Remove style
          </Button>
          <FormControlLabel
            control={
              <Switch
                checked={isBG}
                onChange={handleChange}
                name="background"
                color="primary"
                disabled={!noBGFileUrl}
              />
            }
            label="With Background"
          />
        </Box>
      </Box>
    </Container>
  );
}


