import {IconButton, ImageList, ImageListItem, ImageListItemBar, ListSubheader} from '@mui/material'
import * as React from 'react'

type StylesListProps = {
  onSelected: (style: string) => void;
  selectedKey?: string;
}

export const StylesList = ({onSelected, selectedKey}: StylesListProps) => {
  return (
    <ImageList sx={{ width: 250, height: '100%', paddingRight: '20px', overflowY: 'scroll', scrollbarColor: 'black #939090', scrollbarWidth: 'thin' }} cols={1}>
      <ImageListItem key="Subheader" cols={1}>
        <ListSubheader component="div">Styles</ListSubheader>
      </ImageListItem>
      {itemData.map((item) => (
        <ImageListItem
          key={item.img}
          onClick={() => item.key !== selectedKey && onSelected(item.key)}
          sx={{cursor: item.key === selectedKey ? 'unset' :'pointer', borderColor: 'black', border: item.key === selectedKey ? 3 : 0}}
        >
          <img
            srcSet={`${item.img}`}
            src={`${item.img}`}
            alt={item.title}
            height={120}
            loading="lazy"
            style={{height: '120px'}}
          />
          <ImageListItemBar
            title={item.title}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  )
};

const itemData = [
  {
    img: '/celeba.jpg',
    title: 'Face Celeba',
    key: 'celeba_distill',
  },
  {
    img: '/facePaint.jpg',
    title: 'Face Paint',
    key: 'face_paint_512_v2',
  },
  {
    img: '/paprika.jpg',
    title: 'Face Paprika',
    key: 'paprika',
  },
  {
    img: '/Arcane.jpg',
    title: 'Anim Arcane',
    key: 'Arcane:v2',
  },
  {
    img: '/Hayaov2.jpg',
    title: 'Anim Hayao',
    key: 'Hayao:v2',
  },
  {
    img: '/Shinkaiv2.jpg',
    title: 'Anim Shinkai',
    key: 'Shinkai:v2',
  },
  {
    img: '/rain.jpeg',
    title: 'Rain Princess',
    key: 'rain_princess',
  },
  {
    img: '/mosaic.jpeg',
    title: 'Mosaic',
    key: 'mosaic',
  },
  {
    img: '/starry_night.jpeg',
    title: 'Starry Night',
    key: 'starry_night',
  },
];
