import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {LinearProgress} from '@mui/material';
import prettyBytes from 'pretty-bytes';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import * as React from 'react';

const MAX_FILE_SIZE = 1024 * 1024 * 10;
const VALID_IMAGE_TYPES = ['image/jpeg', 'image/png'];

export type UploadedResult = {
  fileUrl: string;
  noBGFileUrl: string;
  filename: string;
  modelsUrls: {[key: string]: string};
  noBGModelsUrls: {[key: string]: string};
}

type UploadFileProps = {
  onUploaded: (data: UploadedResult) => void;
}

export const UploadFile = ({onUploaded}: UploadFileProps) => {
  const [progress, setProgress] = useState<number>(0);

  const handleFileUpload = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', '/upload', true);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setProgress(percentComplete);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        onUploaded(JSON.parse(xhr.responseText) as UploadedResult);
      } else {
        console.error('File upload failed:', xhr.statusText);
      }
    };

    xhr.onerror = () => {
      console.error('Error uploading file:', xhr.statusText);
    };

    xhr.send(formData);
  };

  return (
    <Box>
      {progress === 0? (
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Upload file
        <VisuallyHiddenInput
          type="file"
          onChange={(event) => {
            const file = event.target.files?.[0];
            if (file) {
              if (!VALID_IMAGE_TYPES.includes(file.type)) {
                alert('Please select a valid image file (jpeg, png).');
                return;
              }
              if (file.size > MAX_FILE_SIZE) {
                alert(`File size should not exceed ${prettyBytes(MAX_FILE_SIZE)}.`);
                return;
              }
              handleFileUpload(file);
            }
          }}
        />
      </Button>) : (
        <>
        {progress > 0 && progress < 100 && <LinearProgress variant="determinate" value={progress} />}
        {progress > 0 && progress < 100 && <p>Upload progress: {progress.toFixed(2)}%</p>}
        {progress === 100 &&<LinearProgress color="success" />}
        {progress === 100 && <p>Removing background...</p>}
        </>
      )}
    </Box>
  );
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
