import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    background: {
      default: '#878484',
    },
    primary: {
      main: '#040303',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    warning: {
      main: '#cc7e11',
    },
  },
  components: {
    MuiListSubheader:{
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 'bold',
          backgroundColor: '#bfbaba',
        }
      }
    }
  },
});

export default theme;
